import styled, { CSSProperties } from "styled-components";

interface SkeletonProps {
    className?: string;
    width?: number | string;
    height?: number | string;
    style?: CSSProperties;
    circle?: boolean;
}

export const SkeletonBlack = styled((props: SkeletonProps) => {
    return (
        <div style={props.style} className={`${props.className}`} />
    );
})`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.width ? typeof props.width === "string" ? props.width : `${props.width}px` : '100%'};
    height: ${props => props.height ? typeof props.height === "string" ? props.height : `${props.height}px` : '100%'};
    background: ${props => props.theme.colors.greyDark};
    animation: wave infinite 1s;
    border-radius: ${props => props.circle ? '50%' : 0};
    @keyframes wave {
        0% {
            background: ${props => props.theme.colors.grey};
        }
        50% {
            background: ${props => props.theme.colors.greyDark};
        }
        100% {
            background: ${props => props.theme.colors.grey};
        }
    }
`;

export const Skeleton = styled(SkeletonBlack)`
background: ${props => props.theme.colors.white};
animation: wave infinite 2s;
@keyframes wave {
    0% {
        background: ${props => props.theme.colors.greyLight};
    }
    50% {
        background: ${props => props.theme.colors.grey};
    }
    100% {
        background: ${props => props.theme.colors.greyLight};
    }
}
    
`
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useReducer, useState } from "react"

interface ICacheLayerContextAPIProps {
    children: any;
}

export interface ICacheLayerContext {
    getItem: (key: string) => string;
    setItem: (key: string, value: string, setLocalStorage?: boolean) => void;
    subscribeItem: (key: string, callback: (value: any) => void) => void;
    clear: () => void;
}

export const CacheLayerContext = createContext<ICacheLayerContext>({
    getItem: (_: string) => "",
    setItem: (_: string, value?: string, setLocalStorage?: boolean) => { },
    subscribeItem: (_: string, callback: any) => { },
    clear: () => { }
});

function reducer(state: { [key: string]: string }, action: { type: "increment" | "decrement" | "clear", key?: string, value?: string }) {
    switch (action.type) {
        case 'increment':
            if (action.key)
                return { ...state, [action.key]: action.value ?? "" };
            else return state;
        case 'decrement': {
            if (action.key)
                delete state[action.key];
            return state;
        }
        case 'clear':
            return {};
        default:
            throw new Error();
    }
};

export function CacheLayerContextAPI(props: ICacheLayerContextAPIProps) {
    const [cache, dispatch] = useReducer(reducer, {});
    const [subscribed, setSubscribed] = useState<{ [key: string]: (value: any) => void }>({});

    const getItem = (key: string) => {
        const storageValue = localStorage.getItem(key);
        return cache[key] ? cache[key] : (storageValue ?? "");
    };

    const setItem = (key: string, value?: string, setLocalStorage?: boolean) => {
        dispatch({
            key,
            value,
            type: "increment"
        });
        if (setLocalStorage)
            localStorage.setItem(key, value ?? "");
        if (subscribed[key] && typeof subscribed[key] === "function")
            subscribed[key](value);
    };

    const clear = () => {
        dispatch({ type: "clear" });
    };

    const subscribeItem = (key: string, callback: (value: any) => void) => {
        setSubscribed(old => ({
            ...old,
            [key]: callback
        }));
    };

    return (
        <CacheLayerContext.Provider value={{ getItem, setItem, clear, subscribeItem }}>
            {
                props.children
            }
        </CacheLayerContext.Provider>
    );
};


export function useReviveCachedState(dispatchers?: { [key: string]: Dispatch<SetStateAction<any>> }): { reviving: boolean, savingDataToRevive: boolean, cacheStates: (states: { [key: string]: any }) => void, clearCachedState: (other?: string[]) => void } {
    const [reviving, setReviving] = useState(true);
    const [savingDataToRevive, setSavingDataToRevive] = useState(true);

    const CacheContext = useContext(CacheLayerContext);

    useEffect(() => {
        if (dispatchers)
            Object.keys(dispatchers).map(stateKey => {
                const stateValue = CacheContext.getItem(stateKey);
                if (stateValue)
                    (dispatchers[stateKey])(JSON.parse(stateValue));
            });

        setReviving(false);
    }, []);

    const cacheStates = (states: { [key: string]: any }) => {
        setSavingDataToRevive(true);
        if (states)
            Object.keys(states).map(stateKey => {
                CacheContext.setItem(stateKey, JSON.stringify(states[stateKey]));
            });
        setSavingDataToRevive(false);
    };

    const clearCachedState = (otherDispatchers?: string[]) => {
        if (otherDispatchers)
            otherDispatchers.map(stateKey => {
                CacheContext.setItem(stateKey, "");
            });
        else if (dispatchers)
            Object.keys(dispatchers).map(stateKey => {
                CacheContext.setItem(stateKey, "");
            });
    };

    return {
        reviving,
        savingDataToRevive,
        cacheStates,
        clearCachedState
    };
};
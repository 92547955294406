import { useEffect, useState } from 'react';
import { IColors, ThemeProvider } from 'styled-components';
import { contrastTheme, darkTheme, lightTheme, font } from './helpers/styles/theme';
import { useTeamsApp } from './helpers/hooks';
import { useWindowSize } from '@essentials/helpers';
import { ToastProvider } from '@essentials/default-styles';

import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './helpers/styles/GlobalStyles';
import { TeamsAuthenticationContextAPI } from './contextAPIs/TeamsAuthenticationContext';
import { AppRouter } from './pagesRouter';
import { useTranslation } from 'react-i18next';

export default function App() {
  const [currentAppTheme, setCurrentAppTheme] = useState<IColors>(lightTheme);
  const { isTeams, currentTheme, isMobile } = useTeamsApp({ initialConfig: true });
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  useEffect(() => {
    const current = currentTheme == "dark" ? darkTheme : currentTheme === "contrast" ? contrastTheme : lightTheme;
    setCurrentAppTheme(current);
  }, [currentTheme]);

  return (
    <ThemeProvider theme={{
      colors: currentAppTheme,
      font,
      windowSize: {
        ...windowSize,
        mobileLimit: 651,
        isMobile: (isMobile || windowSize.width <= 651),
      },
      teamsTheme: currentTheme
    }}>
      <GlobalStyles />
      {
        isTeams !== undefined &&
          isTeams ?
          <>
            <BrowserRouter basename="app">
              <ToastProvider>
                <TeamsAuthenticationContextAPI>
                  <AppRouter />
                </TeamsAuthenticationContextAPI>
              </ToastProvider>
            </BrowserRouter>
          </>
          : isTeams !== undefined &&
          <h3>{t("errors.device")}</h3>
      }
      {
        process.env.REACT_APP_VERSION &&
        process.env.REACT_APP_ENV_NAME && (process.env.REACT_APP_ENV_NAME === "DEV" || process.env.REACT_APP_ENV_NAME === "LOCAL") &&
        localStorage.getItem("showVersion") !== "false" &&
        <div onClick={() => localStorage.setItem("showVersion", "false")} style={{ zIndex: 3000, padding: '2px 10px', position: 'fixed', top: 0, left: 0, background: 'red', fontSize: '12px', color: 'white' }}>
          {process.env.REACT_APP_ENV_NAME} {process.env.REACT_APP_VERSION}
        </div>
      }
    </ThemeProvider>
  );
};
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Description } from '../';

interface IRadioListProps<T> {
    className?: string;
    style?: React.CSSProperties;
    children?: any;
    keyField: keyof T;
    labelField: keyof T;
    disabledField?: keyof T;
    descriptionField?: keyof T;
    readOnly?: boolean;
    errorMessage?: string;
    label?: string;
    onChange?: (item: T, checked?: boolean) => void;
    optionsList?: T[];
    value?: T | null;
    defaultCheckedIndex?: number;
    id?: string | number;
    description?: string;
    disabled?: boolean;
    showLabelAs?: (item: T, value?: any) => string;
}


const RadioListComponent = function <T>(props: IRadioListProps<T>) {
    const { t } = useTranslation("general");

    useEffect(() => {
        if (props.defaultCheckedIndex != undefined && props?.optionsList != undefined && props?.optionsList.length >= props.defaultCheckedIndex) {
            !(props.disabled && props.readOnly) && props.onChange && props.onChange(props.optionsList[props.defaultCheckedIndex], false)
        }
    }, []);

    return (
        <div className={props.className}>
            <label className="labelTitle">{props?.label}
                {
                    props.description &&
                    <Description description={props.description} />
                }
            </label>
            {
                <div style={props.style} className={props.readOnly ? "readOnlyWrapper" : ""}>
                    {
                        !(props.readOnly) ? props.optionsList?.map((element: T, index) => {
                            return (
                                <div className={`radioWrapper ${props.descriptionField ? "withDescription" : ""}`}>
                                    <input
                                        type="radio"
                                        id={`radio_id_${props.id}_${element[props.keyField]}`}
                                        onClick={
                                            () => !(props.disabled && props.readOnly) && props.onChange && props.onChange(element, !!props.value && (props.value[props.keyField] === element[props.keyField]))
                                        }
                                        disabled={!!props.disabled || !!(props.disabledField && element[props.disabledField])}
                                        checked={!!props.value && props.value[props.keyField] === element[props.keyField]}
                                    />
                                    <label className="labelRadio" htmlFor={`radio_id_${props.id}_${element[props.keyField]}`}>
                                        <>
                                            <span className="checkBox" />
                                            {props.showLabelAs ? props.showLabelAs(element, element[props.labelField]) : element[props.labelField]}
                                        </>
                                    </label>
                                    {
                                        props.descriptionField && element[props.descriptionField] &&
                                        <Description description={`${element[props.descriptionField]}`} />
                                    }
                                </div>
                            )
                        }) : (
                            <div className="readOnly">
                                {props.value ? `${props.value[props.labelField]}` : t("")}
                            </div>
                        )
                    }
                </div>
            }
            {
                <span className={`errorMessage ${props.errorMessage && !(props.readOnly) ? "show" : ""}`} >{props.errorMessage}</span>
            }
        </div>
    )
};

export const RadioList = styled(RadioListComponent)`
    position: relative;   
    width: max-content;
    margin-bottom: 32px;
    width: 100%;

    .labelTitle{
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.colors.font};
        position: relative;
        display: flex;
        justify-content: space-between;
        word-break: break-word;
        white-space: pre-wrap;
        -moz-white-space: pre-wrap;

        &.nolabel {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);   
        }
    }

    .radioWrapper{
        margin-top: 4px;
        &.withDescription {
            display: flex;
            align-items: center;
            .labelRadio {
                padding-right: 20px;
            }
        }
    }
    
    .labelRadio{
        color: ${props => props.theme.colors.font};
        display: inline;  
        position: relative;  
        padding-left: calc(14px + 9px);  
        font-size: 14px;
        cursor: pointer;
    }
    .labelRadio .checkBox {  
        position: absolute;  
        cursor: pointer;   
        top: 50%;  
        transform: translateY(-50%);
        left: 0;  
        height: 16px;  
        width: 16px;  
        border: 1px solid grey;
        border-radius: 50%;
    }    
    .labelRadio .checkBox:after {  
        content: "";
        position: absolute;  
        // display: none;  
        opacity: 0;
        transition: .3s;
    }  
    .labelRadio .checkBox:after {  
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.primary};
    }
    input[type="radio"]{
        display: none;
        cursor: pointer;
        position: relative;  
    }
    input[type="radio"]:disabled ~ .labelRadio{
        pointer-events: none;
        opacity: 0.4;
    }
    input[type="radio"]:checked ~ .labelRadio .checkBox:after {  
        // display: block;  
        opacity: 1;
    }
    .readOnlyWrapper{
        display: flex;
        flex-wrap: wrap;
        border-radius: 6px;
        border: 0px;
        width: 100%;
        padding: 10px 13px;
        border: 1px solid grey;
        margin: 9px 0 0 0;
        font-size: 14px;
        min-height: 41px;

        &:focus-visible {
            outline: unset;
        }       
        background: ${props => props.theme.colors.greyLight};
        pointer-events: none;
        user-select: none;
        border: 1px solid transparent;
        -webkit-text-fill-color: #828282;
        color: #828282;
    }
    .readOnly{
        color: ${props => props.theme.colors.font};
    }
    .errorMessage{
        color: ${props => props.theme.colors.danger};
        position: absolute;
        font-size: 14px;
        margin: 2.5px 0;
        opacity: 0;
        transition: .3s;
        &.show {
            opacity: 1;
        }
    }
` as typeof RadioListComponent;
import { createContext, useEffect, useState } from "react";
import { AuthenticationInteraction } from "../common/enums/AuthenticationInteraction";
import { useTeamsApp, useTeamsRouter } from "../helpers/hooks";
import { IUser } from "../interfaces/base/IUser";

import * as microsoftTeams from '@microsoft/teams-js';
import { useUserBusiness } from "../business/base/useUserBusiness";
import { OnboardRouterPaths } from "../common/router/OnboardRouter";
import { SecurityRole } from "../common/enums/SecurityRole";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { useTranslation } from "react-i18next";

export interface IUserContext {
    user: IUser | null;
    isAuthenticated: boolean;
    authToken?: string;
    inProgress: AuthenticationInteraction;
    refreshToken: () => Promise<string>;
}

export const TeamsAuthenticationContext = createContext<IUserContext>({
    user: null,
    isAuthenticated: false,
    inProgress: AuthenticationInteraction.Startup,
    authToken: undefined,
    refreshToken: () => Promise.resolve(""),
});

export function TeamsAuthenticationContextAPI(props: { children: any }) {
    const [user, setUser] = useState<IUser | null>(null);
    const [authToken, setAuthToken] = useState<string | undefined>();
    const [inProgress, setInProgress] = useState<AuthenticationInteraction>(AuthenticationInteraction.Startup);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const { i18n } = useTranslation();

    const { isTeams } = useTeamsApp();
    const { redirect } = useTeamsRouter();
    const { getUserMe, fillProfile } = useUserBusiness();

    const initializeGTM = (user: IUser) => {
        if (process.env.REACT_APP_TAG_MANAGER_ID) {
            console.log("TAG FOUNDED, initializing");
            TagManager.dataLayer({
                dataLayer: {
                    userId: user.id,
                    userMail: user.mail,
                    subscriptionName: user.domainId,
                    selectedLanguage: i18n.language
                }
            });

            const tagManagerArgs: TagManagerArgs = {
                gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
            };
            TagManager.initialize(tagManagerArgs);
        }
    };

    const onAuthSuccess = async (auxAuthToken?: string) => {
        setAuthToken(auxAuthToken);
        getUserMe(auxAuthToken)
            .then((auxUser) => {
                if (auxUser)
                    setUser(auxUser);

                if (!auxUser?.domainId)
                    redirect(`${OnboardRouterPaths.DefaultPath}`); // new customer without account
                else {
                    fillProfile(auxAuthToken);
                    initializeGTM(auxUser);
                }

                setInProgress(AuthenticationInteraction.None);
                setIsAuthenticated(!!auxAuthToken);
            }).catch(() => {
                // setIsAuthenticated(!!auxAuthToken);
                setInProgress(AuthenticationInteraction.None);
            });
    };

    const refreshToken = (): Promise<string> => new Promise((resolve, reject) => {
        if (inProgress === AuthenticationInteraction.None) {
            microsoftTeams.authentication.initialize();

            microsoftTeams.authentication.getAuthToken()
                .then((auth) => {
                    setAuthToken(auth);
                    resolve(auth);
                });
        }
        else {
            resolve(authToken ?? '');
        }
    });

    useEffect(() => {
        if (isTeams && inProgress === AuthenticationInteraction.Startup) {
            setInProgress(AuthenticationInteraction.SsoSilent);
            microsoftTeams.authentication.getAuthToken()
                .then(onAuthSuccess);
        }
    }, [isTeams]);

    return (
        <TeamsAuthenticationContext.Provider value={{
            user,
            isAuthenticated,
            authToken,
            inProgress,
            refreshToken
        }}>
            <>
                {
                    props.children
                }
            </>
        </TeamsAuthenticationContext.Provider>
    );
}
import axios, { AxiosError, AxiosResponse } from "axios";
import { protectedResources } from "../configs/authConfigs";
import { IGeneralService } from "../interfaces/base/IGeneralService";

export class GeneralService implements IGeneralService {
    public _token: string = "";
    private _endPoint: string;
    private _baseUrl: string;
    private _axios;
    private _refreshToken: (() => Promise<string>) | undefined;

    constructor(endPoint: string, refreshToken?: () => Promise<string>) {
        this._endPoint = endPoint;
        this._baseUrl = protectedResources.specialistApi;
        this._refreshToken = refreshToken ?? undefined;
        this._axios = axios.create({
            headers: { "Cache-Control": "no-cache", "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' },
            withCredentials: false
        });
    }

    private refreshToken = async () => {
        if (this._refreshToken) {
            let refreshResponse = await this._refreshToken();
            if (refreshResponse)
                this._token = refreshResponse;
        }
    }

    public GetOnUrl<G>(url?: string, config?: any): Promise<G> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url ?? ""}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.get(`${endpoint}${noCache}`, {
                headers: { "Authorization": `Bearer ${this._token}` },
                ...config
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }

    public PostOnUrl<G>(url: string, body?: any): Promise<G> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.post(`${endpoint}${noCache}`, body, {
                headers: {
                    "Authorization": `Bearer ${this._token}`,

                    withCredentials: false
                }
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        })
    }

    public PutOnUrl<G>(url: string, item: G): Promise<G> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.put(`${endpoint}${noCache}`, item, {
                headers: {
                    "Authorization": `Bearer ${this._token}`
                }
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }

    public DeleteOnUrl(url: string, body?: any): Promise<void> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.delete(`${endpoint}${noCache}`, {
                headers: {
                    body,
                    "Authorization": `Bearer ${this._token}`
                }
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }

    public PostFormDataOnUrl<G>(url: string, formData: FormData): Promise<G> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.post(`${endpoint}${noCache}`, formData, {
                headers: { Authorization: `Bearer ${this._token}`, "Cache-Control": "no-cache", "content-type": "multipart/form-data", 'X-Requested-With': 'XMLHttpRequest' },
                withCredentials: false
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }


    public GetImageOnUrl<G>(url: string): Promise<G> {
        const endpoint: string = `${this._baseUrl}${this._endPoint}${url}`;
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const noCache = `${endpoint.match(/[?]/g) ? '&' : '?'}t=${timestamp}`;
        return new Promise(async (resolve, reject) => {
            await this.refreshToken();
            this._axios.get(`${endpoint}${noCache}`, {
                headers: { Authorization: `Bearer ${this._token}`, "Cache-Control": "no-cache", "response-type": "blob", "content-type": "image/png", 'X-Requested-With': 'XMLHttpRequest' },
                withCredentials: false,
                responseType: 'blob'
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }
}
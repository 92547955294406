import styled from "styled-components"

interface IGridAreaProps {
    className?: string;
    style?: React.CSSProperties;
    children?: any;
    exception?: boolean;
    gap?: number;
}

interface IGridProps {
    columns?: string;
    rows?: string;
    className?: string;
    style?: React.CSSProperties;
    children?: any;
}

export const GridArea = styled((props: IGridAreaProps) => {
    return(
        <div className={`${props.className}`} style={props.style}>
            {
                props.children
            }
        </div>
    )
})`

    height: max-content;
    grid-template-columns: ${props => props.exception ? 
        `repeat(12, calc((calc(100% - ${!!(props.style && props?.style.gap) ?
        `calc(${props?.style.gap.toString().replace(/\D+/g, '')}px * 12)` :         
        !!props.gap ? props.gap * 11 : 0}px) / 12)))` : 
        `repeat(8, calc((calc(100% - ${!!(props.style && props?.style.gap) ? 
        `calc(${props?.style.gap.toString().replace(/\D+/g, '')}px * 7)` : 
        !!props.gap ? props.gap * 7 : 0}px) / 8)))`};
    width: 100%;
    gap: ${props => props.gap}px;
    display: grid;
`
export const Grid = styled((props: IGridProps) => {
    return(
        <div className={`${props.className}`} style={props.style}>
            {
                props.children
            }
        </div>
    )
})`
    display: flex;
    flex-direction: column;
    grid-column: ${props => props.columns};
    grid-row: ${props => props.rows};
    justify-content: center; 
`
import { lazy, Suspense, useContext, useEffect } from 'react';
import { TeamsAuthenticationContext, IUserContext } from '../contextAPIs/TeamsAuthenticationContext';
import { AuthenticatedTemplate } from '../helpers/authTemplates/AuthenticatedTemplate';
import { RelativeLoading } from '../components';
import { Route, Routes } from 'react-router-dom';
import { SessionsRouterPaths } from '../common/router/SessionsRouter';
import { OnboardRouterPaths } from '../common/router/OnboardRouter';
import { AdminRouterPaths } from '../common/router/AdminRouter';
import { hasPermission } from '../helpers/authTemplates/AuthorizatedTemplate';
import { SecurityRole } from '../common/enums/SecurityRole';

const HomePage = lazy(() => import("../pages/app/sessions/homePage/HomePage"));
const CreationPage = lazy(() => import("../pages/app/sessions/creationPage/CreationPage"));
const ScheduledPage = lazy(() => import("../pages/app/sessions/scheduledPage/ScheduledPage"));

const Onboard = lazy(() => import("../pages/app/onboard/Onboard"));
const OnboardDone = lazy(() => import("../pages/app/onboard/OnboardDone"));

export function AppRouter() {
    return (
        <AuthenticatedTemplate context={TeamsAuthenticationContext} fallback={<RelativeLoading show fixed />}>
            <Routes>
                {/* SESSIONS */}
                <Route path={SessionsRouterPaths.DefaultPath}
                    element={
                        <Suspense fallback={<RelativeLoading show fixed transparency />}>
                            <HomePage />
                        </Suspense>
                    }
                />
                <Route path={SessionsRouterPaths.NewSession} element={
                    <Suspense fallback={<RelativeLoading show fixed transparency />}>
                        <CreationPage />
                    </Suspense>
                } />
                <Route path={SessionsRouterPaths.Scheduled} element={
                    <Suspense fallback={<RelativeLoading show fixed transparency />}>
                        <ScheduledPage />
                    </Suspense>
                } />

                {/* ONBOARD */}
                <Route path={OnboardRouterPaths.DefaultPath} element={
                    <Suspense fallback={<RelativeLoading fixed show />}>
                        <Onboard />
                    </Suspense>
                } />
                <Route path={OnboardRouterPaths.DonePath} element={
                    <Suspense fallback={<RelativeLoading fixed show />}>
                        <OnboardDone />
                    </Suspense>
                } />
            </Routes>
        </AuthenticatedTemplate>
    );
};
import { useState } from "react";
import styled from "styled-components";
import { HelpIcon } from "@essentials/icons";


interface IDescriptionProps {
    description: string;
    className?: string;
}

export const Description = styled((props: IDescriptionProps) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [iconPos, setIconPos] = useState<{ left: number, top: number }>({ left: 0, top: 0 });

    const mouseOverIcon = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
        setShowDescription(true);
        const bound = event?.currentTarget.getBoundingClientRect();
        setIconPos({
            left: bound.left,
            top: bound.bottom
        });
    };

    const mouseLeaveIcon = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
        setShowDescription(false);
    }
    return (
        <div className={`${props.className}`}>
            <HelpIcon className={`descriptionIcon ${showDescription ? "show" : ""}`} onMouseOver={mouseOverIcon} onMouseLeave={mouseLeaveIcon} />
            <div className={`showDescription ${showDescription ? "show" : ""}`} style={{ transform: `translate(calc(-100% + 28px + ${iconPos.left}px), calc(-100% - 30px + ${iconPos.top}px))` }}>
                <p>{props.description}</p>
            </div>
        </div>
    );
})`
    .descriptionIcon {
        width: 21px;
        height: 21px;
        display: flex;
        border-radius: 50%;
        stroke: ${props => props.theme.colors.font};
        fill: ${props => props.theme.colors.white};
        transition: .3s;
        position: relative;
        right: 5px;
        &.show {
            color: ${props => props.theme.colors.white};
            fill: ${props => props.theme.colors.primary};
            stroke: ${props => props.theme.colors.primary};
        }
    }
    .showDescription {
        opacity: 0;
        top: 0;
        left: 0;
        pointer-events: none;
        transition: opacity .3s;
        position: fixed;
        background: ${props => props.theme.colors.primary};
        width: 200px;
        border-radius: 4px;
        padding: 15px 10px;
        p {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.colors.fontLight};
        }
        &:after {
            content: '';
            width: 12px;
            height: 12px;
            background: ${props => props.theme.colors.primary};
            transform: rotate(45deg) translateY(calc(-50% - 4px));
            right: 18px;
            top: 100%;
            position: absolute;
        }
        &.show {
            opacity: 1;
        }
    }
`;
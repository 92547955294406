import { CSSProperties, useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import { CacheLayerContext, ICacheLayerContext } from "../../helpers/contextAPIs/CacheLayerContextAPI";
import { base64toBlob } from "@essentials/helpers";
import { CacheLayerContext, ICacheLayerContext } from "../../contextAPIs/CacheLayerContextAPI";

interface IImageProps {
    children?: (isLoading?: boolean, imageUrl?: string, handleError?: () => void) => any;
    downloadFrom?: () => Promise<string>;
    observers?: any[];
    style?: CSSProperties;
    className?: string;
    localStorage?: string;
    forceValue?: string;
}

export const Image = styled((props: IImageProps) => {
    const cacheContext = useContext<ICacheLayerContext>(CacheLayerContext);

    const [downloadBlob, setDownloadBlob] = useState<string>("");
    const [loadingBlob, setLoadingBlob] = useState<boolean>(false);

    const [didRequest, setDidRequest] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (props.localStorage) {
            var blobImg = cacheContext.getItem(props.localStorage);
            var blobImg = "";
            if (blobImg) {
                setDownloadBlob(blobImg);
                setInitialized(true);
            } else
                getBlob();
        } else {
            getBlob();
        }
    }, []);

    useEffect(() => {
        if (initialized)
            getBlob();
    }, props.observers ?? []);

    const handleError = () => {
        if (!didRequest && !props.forceValue)
            getBlob();
        else
            setError(true);
    };

    const getBlob = () => {
        setInitialized(true);
        if (!downloadBlob && !loadingBlob) {
            setLoadingBlob(true);
            setDidRequest(true);
            if (props.downloadFrom)
                props.downloadFrom()
                    .then((response: any) => {
                        if (!response) {
                            setLoadingBlob(false);
                            return;
                        }
                        const b = base64toBlob(response, "application/octet-stream");
                        const objectURL = URL.createObjectURL(b);

                        setDownloadBlob(objectURL);

                        if (!!props.localStorage)
                            cacheContext.setItem(props.localStorage, objectURL);
                        setLoadingBlob(false);
                    }).catch(() => {
                        setLoadingBlob(false);
                    });
            else {
                setLoadingBlob(false);
            }
        }
    };

    return (
        <>
            {
                props.children ?
                    props.children(loadingBlob, props.forceValue ? props.forceValue : downloadBlob, handleError) :
                    !error &&
                    <img onError={handleError} className={props.className} style={props.style} src={props.forceValue ? props.forceValue : downloadBlob} />
            }
        </>
    );
})``;
import { useState, useEffect, useContext } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import { CacheLayerContext } from '../../contextAPIs/CacheLayerContextAPI';
import { CacheKeys } from '../../common/enums/CacheKeys';


function checkInTeams() {
    var microsoftTeamsLib = microsoftTeams;
    if (!microsoftTeamsLib) {
        return false; // the Microsoft Teams library is for some reason not loaded
    }
    if ((window.parent === window.self && (window as any).nativeInterface) ||
        window.navigator.userAgent.includes("Teams/") ||
        window.name === "embedded-page-container" ||
        window.name === "extension-tab-frame") {
        return true;
    }
    return false;
}

export function useTeamsApp(props?: { initialConfig?: boolean }): { isMobile: boolean, currentTheme: string, isTeams?: boolean, context: microsoftTeams.app.Context | null } {
    const [teamsContext, setTeamsContext] = useState<microsoftTeams.app.Context | null>(null);
    const [isTeams, setIsTeams] = useState<boolean | undefined>();
    const [currentTheme, setCurrentTheme] = useState<string>("");
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const cacheContext = useContext(CacheLayerContext);

    const { i18n } = useTranslation();

    const initializeApp = async () => {
        if (!microsoftTeams.app.isInitialized())
            await microsoftTeams.app.initialize();
    }

    useEffect(() => {
        initializeApp().then(() => {
            const inTeams = checkInTeams();
            setIsTeams(inTeams);

            if (inTeams) {
                microsoftTeams.app.getContext()
                    .then((context) => {
                        setTeamsContext(context);
                        setCurrentTheme(context.app.theme);

                        setIsMobile(context?.app?.host?.clientType !== microsoftTeams.HostClientType.desktop && context?.app?.host?.clientType !== microsoftTeams.HostClientType.web);
                    });

                microsoftTeams.app.notifyAppLoaded();
                microsoftTeams.app.notifySuccess();

                if (props?.initialConfig && !isInitialized) {
                    microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
                        setCurrentTheme(theme);
                    });

                    const currentLang = cacheContext.getItem(CacheKeys.CurrentLenguage);
                    if (currentLang)
                        i18n.changeLanguage(currentLang);
                    else if (teamsContext?.app.locale.match('en')) {
                        cacheContext.setItem(CacheKeys.CurrentLenguage, "en", true);
                        i18n.changeLanguage('en');
                    } else {
                        cacheContext.setItem(CacheKeys.CurrentLenguage, "pt", true);
                        i18n.changeLanguage('pt');
                    }
                    setIsInitialized(true);
                }
            }
        })
    }, []);

    return {
        isTeams,
        context: teamsContext,
        currentTheme,
        isMobile,
    };
}
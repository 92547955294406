import { Context, createElement, Fragment, useContext } from "react";
import { AuthenticationInteraction } from "../../common/enums/AuthenticationInteraction";
import { IUserContext } from "../../contextAPIs/TeamsAuthenticationContext";
import { returnChildrenOrFunction } from "@essentials/helpers";

export function AuthenticatedTemplate(props: { context: Context<IUserContext>, children: any, fallback?: any }) {
    const userContext = useContext<IUserContext>(props.context);

    if (userContext?.inProgress !== AuthenticationInteraction.Startup && userContext?.isAuthenticated) {
        return createElement(Fragment, null, returnChildrenOrFunction(props.children));
    }

    return props.fallback ?? null;
}
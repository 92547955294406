import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        padding: 0;
        box-sizing: border-box;
        line-height: 1.8em;
        font-family: ${props => props.theme.font.family};
        transition: color .2s, background .2s;
        user-select: none;
    }

    button {
        color: ${props => props.theme.colors.font};
    }

    body {
        margin: 0;
        background: ${props => props.theme.colors.white};
    }

    .scrollable, body {
        overflow-y: auto;
        overflow-x: hidden;
        
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.colors.primary};
            border-radius: 20px;
        }
        ::-webkit-scrollbar-track {
            border: 2px solid ${props => props.theme.colors.white};
            background-color: ${props => props.theme.colors.greyDark};
        }
    }

    .scrollable {
        overflow-x: auto;
    }

    .scrollable-x {
        overflow-x: auto;

        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.colors.primary};
            border-radius: 20px;
        }
        ::-webkit-scrollbar-track {
            border: 2px solid ${props => props.theme.colors.white};
            background-color: ${props => props.theme.colors.greyDark};
        }
    }

    .scrollable-y {
        overflow-y: auto;

        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.colors.primary};
            border-radius: 20px;
        }
        ::-webkit-scrollbar-track {
            border: 2px solid ${props => props.theme.colors.white};
            background-color: ${props => props.theme.colors.greyDark};
        }
    }

    .noScrollBar {
        /* hide scrollbar but allow scrolling */
        & {
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
        }

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    .defaultFixedTop {
        max-width: 1368px;
        margin: 0 auto 0;

        @media screen and (min-width: 1369px) {
            margin: 0 auto 0;
            padding: 0 100px;
        }
        @media screen and (max-width: 1369px) {
            padding: 0 50px;
        }
        @media screen and (max-width: 900px) {
            padding-left: 10px;
            padding-right: 10px;
            margin: 0 auto 0;

            max-width: unset;
            width: 100%;
        }
    }

    .defaultPage {
        max-width: 1368px;
        min-height: 100vh;
        margin: 0 auto 0;
        padding-bottom: 30px;
        padding-top: 25px;
        position: relative;
    
        @media screen and (min-width: 1369px) {
            margin: 0 auto 0;
            padding: 20px 100px;
        }
        @media screen and (max-width: 1369px) {
            padding: 20px 50px;
        }
        @media screen and (max-width: 900px) {
            padding: 20px 10px;
            max-width: unset;
            margin: 0 auto 0;
            width: 100%;
        }
        @media screen and (max-width: 700px) {
            padding: 20px 10px;
        }
        @media screen and (max-width: 500px) {
            padding: 45px 10px 20px;
        }
    }

    .pageAnimation {
        @media (prefers-reduced-motion: no-preference) {
            -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein 0.3s; /* Firefox < 16 */
            -ms-animation: fadein 0.3s; /* Internet Explorer */
            -o-animation: fadein 0.3s; /* Opera < 12.1 */
            animation: fadein 0.3s;
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`
import { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { base64toBlob } from '@essentials/helpers';

interface IDownloadLinkProps {
    id?: string | number;
    children?: any;
    fileName: string;
    downloadFrom: () => Promise<string>;
    className?: string;
    style?: CSSProperties;
}

export const DownloadLink = styled((props: IDownloadLinkProps) => {
    const anchorRef = useRef<HTMLAnchorElement>(null);
    const wrapperVideo = useRef<HTMLVideoElement>(null);

    const [downloadBlob, setDownloadBlob] = useState<any>("");
    const [loadingBlob, setLoadingBlob] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const clickHiddenAnchor = (obj?: string) => {
        if (anchorRef?.current?.click) {
            anchorRef.current.click();
        }

        // setModalOpen(false);
        setLoadingBlob(false);
    };

    const getBlob = () => {
        if (!downloadBlob && !loadingBlob) {
            setLoadingBlob(true);
            props.downloadFrom()
                .then((response) => {
                    const b = base64toBlob(response, "application/octet-stream");
                    var obj = URL.createObjectURL(b);

                    if (wrapperVideo.current)
                        wrapperVideo.current.src = obj ?? downloadBlob;
                    wrapperVideo.current?.load();
                    wrapperVideo.current?.play();

                    setDownloadBlob(obj);
                    setLoadingBlob(false);
                });
        }
    };

    useEffect(() => {
        if (modalOpen)
            getBlob();
    }, [modalOpen]);

    return (
        <>
            <div onClick={() => setModalOpen(true)} className={props.className} style={props.style}>
                <a href={downloadBlob} download={props.fileName} id={props.fileName} ref={anchorRef} />
                {
                    typeof props.children === "function" ?
                        props.children(loadingBlob, setLoadingBlob)
                        : props.children
                }
            </div>
            {/* <Modal id={`downloadItem-${props.id}`} isOpen={!!modalOpen} onClose={() => setModalOpen(false)} > */}
            {/* <DefaultModal>
                    <div className={"defaultModalHeader"}>
                        <h3>VISUALIZAÇÃO DE ARQUIVO</h3>
                    </div>
                    <div className={"defaultModalContent"}>
                        <p>
                            Visualizando o arquivo <strong>{props.fileName}</strong>.
                        </p>

                        <DataViewer
                            blobUrl={downloadBlob}
                            fileName={props.fileName}
                            loading={loadingBlob}
                        />
                    </div>
                    <div className={"defaultModalFooter"}>
                        <Button disabled={loadingBlob} onClick={() => { setModalOpen(false); console.log("Closing", loadingBlob) }} style={{ marginRight: 15 }}>Fechar</Button>
                        {
                            !theme.windowSize.isMobile &&
                            < FilledButton onClick={clickHiddenAnchor} loading={loadingBlob} disabled={loadingBlob}>Download</FilledButton>
                        }
                    </div>
                </DefaultModal> */}
            {/* </Modal> */}
        </>
    );
})`

`;
import { AxiosError } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { APIEndpoints } from "../../common/enums/APIEndpoints";
import { TeamsAuthenticationContext } from "../../contextAPIs/TeamsAuthenticationContext";
import { ToastContext } from "@essentials/default-styles";
import { axiosErrorResponseString } from "../../helpers/functions/axiosErrorResponse";
import { IApiError } from "../../interfaces/base/IApiError";
import { IUser } from "../../interfaces/base/IUser";
import { UserRepository } from "../../repositories/base/UserRepository";
import { GeneralService } from "../../services/GeneralService";
import { APIErrorCodes } from "../../common/enums/GraphErrorType";
import * as microsoftTeams from '@microsoft/teams-js';

export function useUserBusiness() {
    const userContext = useContext(TeamsAuthenticationContext);
    const toastContext = useContext(ToastContext);
    const generalService = new GeneralService(APIEndpoints.User, userContext.refreshToken);
    const userRepository = new UserRepository();
    const { t } = useTranslation("general");

    return {
        getUserMe: (idToken?: string): Promise<IUser | undefined> => new Promise((resolve, reject) => {
            generalService._token = idToken ?? generalService._token;
            generalService.GetOnUrl<(IUser | undefined)>(`/me`)
                .then((response) => {
                    console.log("USER FROM REPO", userRepository.mapObject(response))
                    resolve(response ?? userRepository.mapObject(response));
                }).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.getError", { entity: t("entities.user") }), err),
                        itemKey: "userError",
                        type: "danger"
                    });
                    reject(err);
                });
        }),

        getUserPicture: (id: number): Promise<string> => new Promise((resolve, reject) => {
            generalService.GetOnUrl<string>(`/${id}/picture`)
                .then((response) => {
                    resolve(response);
                }).catch((err: AxiosError<IApiError>) => {
                    // toastContext.addToast({
                    //     title: axiosErrorResponseString(t("errors.getError", { entity: t("entities.userPicture") }), err),
                    //     itemKey: "userPictureError",
                    //     type: "danger"
                    // });
                    reject(err);
                });
        }),

        fillProfile: (idToken?: string): Promise<void> => new Promise((resolve, reject) => {
            generalService._token = idToken ?? generalService._token;
            generalService.PostOnUrl<void>(`/fill-profile`)
                .then(() => resolve()).catch((err: AxiosError<IApiError>) => {
                    if (err.response?.data.type === APIErrorCodes.IvalidGrant) {
                        microsoftTeams.authentication.authenticate({
                            url: window.location.origin + "/popup-consent-start.html",
                            width: 600,
                            height: 535
                        }).then(async () => {
                            generalService.GetOnUrl("/fill-profile")
                                .then(() => resolve()).catch(reject);
                        });
                    }
                    reject(err);
                });
        }),
    };
};
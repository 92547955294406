import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


i18n.use(initReactI18next).init({
    fallbackLng: 'pt',
    lng: 'pt',
    resources: {
        en: {
            general: require('./locales/en/general.json'),
            sessions: require('./locales/en/sessions.json'),
            onboard: require('./locales/en/onboard.json'),
            preScheduleEditor: require('./locales/en/preScheduleEditor.json'),
            subscriptionAdmin: require('./locales/en/subscriptionAdmin.json'),
        },
        pt: {
            general: require('./locales/pt/general.json'),
            sessions: require('./locales/pt/sessions.json'),
            onboard: require('./locales/pt/onboard.json'),
            preScheduleEditor: require('./locales/pt/preScheduleEditor.json'),
            subscriptionAdmin: require('./locales/pt/subscriptionAdmin.json'),
        }
    },
    ns: ['general', 'sessions', 'onboard', 'subscriptionAdmin', 'preScheduleEditor'],
    defaultNS: 'general'
});

i18n.languages = ['en', 'pt'];

export default i18n;
import * as microsoftTeams from '@microsoft/teams-js';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { TeamsEntities } from '../../common/enums/TeamsEntities';
import { SessionsRouterPaths } from '../../common/router/SessionsRouter';
import { useTeamsApp } from './useTeamsApp';

export function useTeamsRouter(props?: { subscribeTheme?: boolean }): { deepRedirectUrl: (path: string) => void, redirect: (path: To | number, options?: NavigateOptions | undefined) => void, goToEntityDefaultPath?: <T>() => void } {
    const navigate = useNavigate();
    const { context } = useTeamsApp();
    const redirect = (path: To | number, options?: NavigateOptions | undefined) => {
        if (typeof path === "number")
            navigate(path);
        else
            navigate(path, options);
    };

    const deepRedirectUrl = (path: string) => {
        microsoftTeams.app.openLink(path);
    };

    const goToEntityDefaultPath = () => {
        switch (context?.page?.id) {
            case TeamsEntities.Home: return redirect(SessionsRouterPaths.DefaultPath);
            case TeamsEntities.Schedules: return redirect(SessionsRouterPaths.Scheduled);
            default: return redirect(SessionsRouterPaths.DefaultPath);
        }
    };

    return {
        deepRedirectUrl,
        redirect,
        goToEntityDefaultPath
    };
}
import { CSSProperties } from "react";
import styled from "styled-components";

interface IScreenBlurProps {
    className?: string;
    blur?: number;
    size?: number;
    style?: CSSProperties;
}

export const ScreenBlur = styled(function (props: IScreenBlurProps) {
    return (
        <div className={props.className} />
    );
})`
    width: 100%;
    display: flex;
    height: 0;
    z-index: 0;
    &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 1px;
        top: ${props => props.style?.top ?? ""};
        left: ${props => props.style?.left ?? ""};
        box-shadow: ${props => ` 0px 0px ${props.size}px ${props.blur}px ${props.theme.colors.secondary}44`};
        transform: ${props => props.style?.transform ?? ""};
        background: ${props => props.theme.colors.secondary}1f;
        z-index: -1;
    }
`;